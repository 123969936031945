import React, { useState } from "react";

import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaRegStar, FaStar } from "react-icons/fa";

const OurTestimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const motionVariantsTop = {
    hidden: { opacity: 0, y: -40 },
    visible: { opacity: 1, y: 0 },
  };

  const motionProperties = {
    initial: "hidden",
    whileInView: "visible",
    transition: { duration: 1, delay: 0.5 },
    viewport: { once: true },
  };
  return (
    <>
      <div
        className="lg:px-20 md:px-10 px-5 py-20"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('https://images.pexels.com/photos/919734/pexels-photo-919734.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div className="flex justify-center md:flex-row flex-col items-center gap-20 py-5">
            <div className=" text-white ">
              <motion.h1
                {...motionProperties}
                variants={motionVariantsTop}
                className="md:text-4xl text-xl font-bold tracking-wider"
              >
                Testimonials
              </motion.h1>
              <p className="mt-4 text-base tracking-wider">
                Ready to hear what our customers have to say? Visit our website
                to read testimonials from homeowners, business owners, and
                property managers who have experienced the difference that Web
                Bocket Software Pvt. Ltd. can make.
                <br />
                At Techmentee, nothing speaks louder than the voices of our
                satisfied customers. We take great pride in the positive impact
                our services have on their homes and businesses. Here’s what
                some of them have to say about their experience with us:
              </p>
            </div>
          </div>
        </div>

        <div className="mt-">
          <Slider {...settings}>
            <div className="p-4">
              <div className="flex flex-row justify-center items-center bg-[#424241b6] rounded-[70px] max-w-lg mx-auto my-8">
                <div className="text-white relative -top-10  text-center px-10">
                  <img
                    src={
                      "https://i.pinimg.com/564x/90/be/61/90be6143826c81c3f2d87e7c25e0c2e9.jpg"
                    }
                    alt="Ajay S."
                    className="w-20 h-20 rounded-full mb-4 mx-auto"
                  />
                  <div className="flex justify-center gap-1 m-2">
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaRegStar className="text-primaryExtraLightOrange" />
                  </div>
                  <p className="text-sm md:text-base leading-relaxed mb-4">
                  Techmentee Software Pvt Ltd. transformed our business
                    operations with their impeccable software solutions. Their
                    team's dedication and expertise helped us streamline
                    processes and achieve remarkable growth. Highly recommended!
                  </p>

                  <p className="text-base md:text-lg mt-4 text-primaryLightOrange">
                    Emily Smith
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <div className="flex flex-row justify-center items-center bg-[#424241b6] rounded-[70px] max-w-lg mx-auto my-8">
                <div className="text-white relative -top-10  text-center px-10">
                  <img
                    src={
                      "https://i.pinimg.com/736x/cd/74/8d/cd748df22ba165cd40f9b2e94713e551.jpg"
                    }
                    alt="Ajay S."
                    className="w-20 h-20 rounded-full mb-4 mx-auto"
                  />
                  <div className="flex justify-center gap-1 m-2">
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaRegStar className="text-primaryExtraLightOrange" />
                  </div>
                  <p className="text-sm md:text-base leading-relaxed mb-4">
                    "We are extremely pleased with the services provided by
                    Techmentee Software Pvt Ltd. Their commitment to delivering
                    high-quality products coupled with exceptional support has
                    been instrumental in our success. Thank you for being a
                    reliable partner!"
                  </p>

                  <p className="text-base md:text-lg mt-4">
                    <p className="text-base md:text-lg mt-4 text-primaryLightOrange">
                      Aditya Shankar
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <div className="flex flex-row justify-center items-center bg-[#424241b6] rounded-[70px] max-w-lg mx-auto my-8">
                <div className="text-white relative -top-10  text-center px-10">
                  <img
                    src={
                      "https://i.pinimg.com/736x/0b/b4/f3/0bb4f30d51ecb2bd9547acb15795391f.jpg"
                    }
                    alt="Ajay S."
                    className="w-20 h-20 rounded-full mb-4 mx-auto"
                  />
                  <div className="flex justify-center gap-1 m-2">
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaStar className="text-primaryExtraLightOrange" />
                    <FaRegStar className="text-primaryExtraLightOrange" />
                  </div>
                  <p className="text-sm md:text-base leading-relaxed mb-4">
                    "Techmentee Software Pvt Ltd. exceeded our expectations with
                    their innovative software solutions tailored to our specific
                    requirements. Their team's professionalism and attention to
                    detail have greatly contributed to optimizing our
                    operations. We look forward to continuing our
                    collaboration."
                  </p>

                  <p className="text-base md:text-lg mt-4">
                    <p className="text-base md:text-lg mt-4 text-primaryLightOrange">
                      Yamini Yadav
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

export default OurTestimonials;
