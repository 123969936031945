import React from "react";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceOut,
  motionVariantsLeft,
  motionVariantsScaleUp,
  motionVariantsZoomIn,
} from "../common/animations";

const WhoWeAre = () => {
  return (
    <div className="py-10 overflow-hidden">
      <div className="tracking-wider">
        <motion.h1
          variants={motionVariantsBounceOut}
          {...motionProperties}
          className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor"
        >
          Who We Are
        </motion.h1>
        <motion.p
          variants={motionVariantsLeft}
          {...motionProperties}
          className="text-center m-auto text-sm lg:w-[80%] text-secondaryTextColor leading-6"
        >
          Our Company TECHMENTEE is a prominent and innovative software company,
          that was founded on March 24, 2023. With a core focus on providing a
          wide range of services in the IT industry. TECHMENTEE has quickly
          established itself as a leading player in the market. The company's
          dedication to excellence and commitment to customer satisfaction has
          earned it a reputation as a reliable and trustworthy partner for
          businesses seeking top-notch IT solutions.
        </motion.p>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 mt-10 lg:gap-0 gap-8">
        <div className="grid grid-cols-2  gap-4 lg:h-auto h-[400px]">
          <motion.div
            variants={motionVariantsZoomIn}
            {...motionProperties}
            className="flex  h-auto  rounded-md text-center items-center justify-center bg-primaryLightOrange text-white"
          >
            <div className=" flex flex-col gap-2">
              <h1 className="text-4xl font-medium">50+</h1>
              <p className="text-xl font-medium">Delighted Clients</p>
            </div>
          </motion.div>
          <div className="flex  rounded-md text-center items-end">
            <motion.div
              variants={motionVariantsZoomIn}
              {...motionProperties}
              className=" flex flex-col items-center justify-center gap-2 rounded-md md:w-[70%] w-full md:h-[70%] h-full shadow-orange-200 shadow-lg"
            >
              <h1 className="text-4xl font-medium">30+</h1>
              <p className="md:text-xl font-medium">Team Members</p>
            </motion.div>
          </div>
          <motion.div
            variants={motionVariantsZoomIn}
            {...motionProperties}
            className="flex  rounded-md text-center items-start justify-end "
          >
            <div className="flex flex-col items-center justify-center gap-2 shadow-orange-200 shadow-lg md:h-[95%] h-full md:w-[95%] w-full rounded-md">
              <h1 className="text-4xl font-medium">30+</h1>
              <p className="text-xl font-medium">Technologies</p>
            </div>
          </motion.div>
          <div className="flex  rounded-md text-center items-center text-white">
            <motion.div
              variants={motionVariantsZoomIn}
              {...motionProperties}
              className="flex flex-col items-center justify-center gap-2 bg-primaryExtraLightOrange rounded-md md:w-[80%] w-full md:h-[80%] h-full shadow-orange-100 shadow-lg"
            >
              <h1 className="text-4xl font-medium">1+</h1>
              <p className="md:text-xl font-medium">Year of Trust</p>
            </motion.div>
          </div>
        </div>
        <div>
          <motion.form
            variants={motionVariantsLeft}
            {...motionProperties}
            action=""
            className="bg-[#ffe3d7] flex flex-col gap-4 px-8 py-12 rounded-lg"
          >
            <h1 className="text-2xl font-medium text-center my-6">
              Inquiry Form
            </h1>
            <input
              type="text"
              placeholder="Enter your name"
              className="bg-white px-4 py-2 border w-full rounded-md"
            />
            <input
              type="text"
              placeholder="Enter your Mob number"
              className="bg-white px-4 py-2 border w-full rounded-md"
            />
            <input
              type="text"
              placeholder="Enter your email address"
              className="bg-white px-4 py-2 border w-full rounded-md"
            />
            <textarea
              type="text"
              rows={6}
              placeholder="something you want to say...."
              className="bg-white px-4 py-2 border w-full rounded-md"
            />
            <button className="bg-gradient-to-r from-primaryDarkOrange to-primaryLightOrange hover:from-primaryLightOrange hover:to-primaryDarkOrange transition duration-200 ease-linear text-white py-2 w-[70%] m-auto rounded">
              Inquiry Now
            </button>
          </motion.form>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
